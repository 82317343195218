import * as React from "react";

type EditingContext = "cv" | "rp" | "masterdata" | null;

const Context = React.createContext<EditingContext>(null);

export const CvEditingContext: React.FC = ({ children }) => (
  <Context.Provider value="cv">{children}</Context.Provider>
);

export const ReferenceProjectEditingContext: React.FC = ({ children }) => (
  <Context.Provider value="rp">{children}</Context.Provider>
);

export const MasterdataEditingContext: React.FC = ({ children }) => (
  <Context.Provider value="masterdata">{children}</Context.Provider>
);

export const NoEditingContext: React.FC = ({ children }) => (
  <Context.Provider value={null}>{children}</Context.Provider>
);

export function useEditingContext(): EditingContext {
  return React.useContext(Context);
}
