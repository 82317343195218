export const uiLanguages = ["int", "no", "se", "dk", "fi", "fr"] as const;

// sync with https://github.com/cvpartner/cvpartner/blob/9fa168aa685b8dbc7e6f92d49b78e56899467bae/lib/utils/country_code.rb#L6-L11
// & https://github.com/cvpartner/cvpartner/blob/9fa168aa685b8dbc7e6f92d49b78e56899467bae/lib/utils/language_code.rb#L8-L12
export const defaultCountryLanguageMapper = new Map([
  ["ae", "ar"], // country United Arab Emirates - Arabic
  ["al", "sq"], // country Albania - Albanian
  ["am", "hy"], // country Armenia - Armenian
  ["ao", "pt"], // country Angola - Portuguese
  ["ar", "es"], // country Argentina - Spanish
  ["at", "de"], // country Austria - German
  ["au", "en"], // country Australia - English
  ["az", "az"], // country Azerbaijan - Azerbaijani
  ["be", "nl"], // country Belgium - Dutch
  ["bg", "bg"], // country Bulgaria - Bulgarian
  ["bn", "en"], // country Brunei - English (should possibly be Negara Brunei Darussalam)
  ["br", "pt"], // country Brazil - Portuguese
  ["by", "be"], // country Belarus - Belarusian
  ["ca", "en"], // country Canada - English
  ["cg", "fr"], // country Republic of the Congo - French
  ["ch", "de"], // country Switzerland - German
  ["ci", "fr"], // country Cote D'Ivoire - French
  ["cl", "es"], // country Chile - Spanish
  ["cn", "zh"], // country China - Chinese
  ["co", "es"], // country Colombia - Spanish
  ["cy", "el"], // country Cyprus - Greek
  ["cz", "cs"], // country Czech Republic - Czech
  ["de", "de"], // country Germany - German
  ["dk", "da"], // country Denmark - Danish
  ["ee", "et"], // country Estonia - Estonian
  ["eg", "ar"], // country Egypt - Arabic
  ["es", "es"], // country Spain - Spanish
  ["et", "en"], // country Ethiopia - English (this _should_ be Amharic, but its code clashes with legacy language code for Armenian)
  ["fi", "fi"], // country Finland - Finnish
  ["fj", "en"], // country Fiji - English
  ["fr", "fr"], // country France - French
  ["ga", "fr"], // country Gabon - French
  ["gb", "en"], // country United Kingdom - English
  ["ge", "ka"], // country Georgia - Georgian
  ["gh", "en"], // country Ghana - English
  ["gn", "fr"], // country Guinea - French
  ["gr", "el"], // country Greece - Greek
  ["hk", "zh"], // country Hong Kong - Chinese
  ["hr", "hr"], // country Croatia - Croatian
  ["hu", "hu"], // country Hungary - Hungarian
  ["id", "id"], // country Indonesia - Indonesian
  ["ie", "en"], // country Ireland - English
  ["il", "he"], // country Israel - Hebrew
  ["in", "hi"], // country India - Hindi
  ["is", "is"], // country Iceland - Icelandic
  ["it", "it"], // country Italy - Italian
  ["jo", "ar"], // country Jordan - Arabic
  ["jp", "ja"], // country Japan - Japanese
  ["ke", "en"], // country Kenya - English
  ["kr", "ko"], // country South Korea - Korean
  ["kz", "kk"], // country Kazakhstan - Kazakh
  ["lb", "ar"], // country Lebanon - Arabic
  ["lk", "ta"], // country Sri Lanka - Tamil
  ["lt", "lt"], // country Lithuania - Lithuanian
  ["lu", "fr"], // country Luxembourg - French
  ["lv", "lv"], // country Latvia - Latvian
  ["ma", "ar"], // country Morocco - Arabic
  ["me", "sr"], // country Montenegro - Serbian
  ["mk", "mk"], // country North Macedonia - Macedonian
  ["mu", "en"], // country Mauritius - English
  ["mx", "es"], // country Mexico - Spanish
  ["my", "ms"], // country Malaysia - Malay
  ["mz", "pt"], // country Mozambique - Portuguese
  ["na", "en"], // country Namibia - English
  ["nc", "fr"], // country New Caledonia - French
  ["ng", "en"], // country Nigeria - English
  ["nl", "nl"], // country Netherlands - Dutch
  ["no", "no"], // country Norway - Norwegian
  ["nz", "en"], // country New Zealand - English
  ["om", "ar"], // country Oman - Arabic
  ["pe", "es"], // country Peru - Spanish
  ["ph", "en"], // country Philippines - English
  ["pl", "pl"], // country Poland - Polish
  ["pt", "pt"], // country Portugal - Portuguese
  ["py", "es"], // country Paraguay - Spanish
  ["qa", "ar"], // country Qatar - Arabic
  ["ro", "ro"], // country Romania - Romanian
  ["rs", "sr"], // country Serbia - Serbian
  ["ru", "ru"], // country Russia - Russian
  ["rw", "en"], // country Rwanda - English
  ["sa", "ar"], // country Saudi Arabia - Arabic
  ["se", "sv"], // country Sweden - Swedish
  ["sg", "en"], // country Singapore - English
  ["si", "sl"], // country Slovenia - Slovenian
  ["sk", "sk"], // country Slovakia - Slovak
  ["sn", "fr"], // country Senegal - French
  ["sz", "en"], // country Eswatini - English
  ["th", "th"], // country Thailand - Thai
  ["tn", "ar"], // country Tunisia - Arabic
  ["tr", "tr"], // country Turkey - Turkish
  ["ua", "uk"], // country Ukraine - Ukrainian
  ["ug", "sw"], // country Uganda - Swahili
  ["us", "en"], // country United States - English
  ["uz", "uz"], // country Uzbekistan - Uzbek
  ["vn", "vi"], // country Vietnam - Vietnamese
  ["za", "en"], // country South Africa - English
  ["zm", "en"], // country Zambia - English
  ["zw", "en"], // country Zimbabwe - English
] as const);

export const languageMappings = new Map([
  ["en", "int"],
  ["sv", "se"],
  ["da", "dk"],
  ["be", "by"],
  ["cs", "cz"],
  ["hi", "in"],
  ["kk", "kz"],
  ["lb", "lu"],
  ["ms", "my"],
  ["uk", "ua"],
  ["zh", "cn"],
] as const);

export const languageMappingsReverse = new Map(
  Array.from(languageMappings).map(([real, fake]) => [fake, real]),
);

export const toIso = (language_code: string) =>
  languageMappingsReverse.get(language_code as any) ?? language_code;

export function getDefaultUiLanguageForLanguage(
  language: string | undefined,
): UiLanguage {
  return language === "no"
    ? "no"
    : language === "sv"
      ? "se"
      : language === "da"
        ? "dk"
        : language === "fi"
          ? "fi"
          : language === "fr"
            ? "fr"
            : "int";
}

export function getDefaultLanguageForCountry(country: string) {
  let defaultLanguageForCountry = defaultCountryLanguageMapper.get(
    country as any,
  );
  let defaultUiLanguageForLanguage = getDefaultUiLanguageForLanguage(
    defaultLanguageForCountry,
  );
  return defaultUiLanguageForLanguage;
}

export type UiLanguage = (typeof uiLanguages)[number];
